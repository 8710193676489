import React, {
  Suspense,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import {
  Center,
  ChakraProvider,
  Spinner,
  Text,
  extendTheme,
} from "@chakra-ui/react";

// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
// This particular import is a friendly wrapper around React.lazy to allow for retries
// Package works cross browser
import LazyReact from "@fatso83/retry-dynamic-import/react-lazy";
import { ErrorBoundary, Provider } from "@rollbar/react";
import tinycolor from "tinycolor2";

import { FeatureFlags } from "./types";

import FontFace from "./theme/font-face";
import theme from "./theme/index";

import MagicLinkLogin from "./MagicLinkLogin";
import StripeRedirectPage from "./StripeRedirectPage";
import TaxFilingSDK from "./diy/TaxFilingSDK";
import { getNav } from "./diy/api";
import {
  NavigationMenuContext,
  NavigationMenuState,
} from "./diy/helpers/navigationMenuContext";
import { reportDeviceId } from "./diy/helpers/reportDeviceId";
import { NavObject, Screen, UserDetails } from "./diy/types";
import rollbar, { configurePerson } from "./rollbar-utils";
import ColumnTaxUnavailableScreen from "./screens/ColumnTaxUnavailableScreen";
import DevErrorScreen from "./screens/DevErrorScreen";
import { getInitialPayloadForDIY } from "./utils/params-utils";
import {
  isHostedInternal,
  isInternalUse,
  isProduction,
  isSandbox,
} from "./utils/utils";

const AdminDashboard = LazyReact(() => import("./AdminDashboard"));
const AdminLoginPage = LazyReact(() => import("./AdminLoginPage"));
const ExitPage = LazyReact(() => import("./ExitPage"));
// Please do not remove lazy loading from the CheckoutComponent! We need this here!
// https://coda.io/d/Eng-runbooks-and-reports_dk9kbmlx-N4/2024-01-25-js-stripe-com-show-on-Current-app_sugYM#_lus5_
// The issue is that this checkout component calls loadStripe, which injects Stripe.js into
// the window object. This caused issues with Current who has a ios implementation
// We have not dug into how to prevent this.
const CheckoutComponent = LazyReact(() => import("./CheckoutComponent"));

const HostBankDashboard = LazyReact(() => import("./HostBankDashboard"));
const SubmissionDashboard = LazyReact(() => import("./SubmissionDashboard"));
const ManualReviewSubmissionDashboard = LazyReact(
  () => import("./ManualReviewSubmissionDashboard"),
);
const ReviewQueue = LazyReact(() => import("./ReviewQueue"));
const SubmissionRules = LazyReact(() => import("./SubmissionRules"));
const TrainingPage = LazyReact(() => import("./TrainingPage"));
const UserReviewPage = LazyReact(() => import("./UserReviewPage"));

const CatalogEditorScreen = LazyReact(
  () => import("./diy/internal/catalog/CatalogEditorScreen"),
);
const ScreenBuilderScreen = LazyReact(
  () => import("./diy/internal/screenbuilder/ScreenBuilderScreen"),
);

const CatalogStart = LazyReact(
  () => import("./diy/internal/catalog/CatalogStart"),
);

export const FeatureFlagContext = createContext<null | FeatureFlags>(null);

// Polyfill Object.fromEntries (which chakra-ui uses) for older browsers
// https://rollbar.com/column-be/column-fe/items/6
Object.fromEntries =
  Object.fromEntries ||
  // eslint-disable-next-line
  function (arr: Array<any>) {
    return arr.reduce(function (obj, [key, value]) {
      obj[key] = value;
      return obj;
    }, {});
  };

// We polyfill process.env for `json-diff-react`
window.process = { env: {} } as unknown as NodeJS.Process;

// TODO(marcia): After deploys, we manually visit /throw-error to trigger
// an error so that Rollbar will download sourcemaps. Then, any subsequent,
// real errors will have better stack traces because the sourcemaps will
// already be available to Rollbar.
// https://coda.io/d/Column-Tax_dGbV9r8X0AF/Eng-Tasks_suoPP#Main-Task-List_tubfG/r511&modal=true
// https://coda.io/d/Column-Tax_dGbV9r8X0AF/Eng-Tasks_suoPP#Board-View-of-Engineering-tasks_tudLo/r596&modal=true
function ThrowErrorForRollbar() {
  rollbar.error(
    "Hi! I'm an intentional error to force Rollbar to download sourcemaps",
  );
  return <></>;
}

function ThrowUncaughtErrorForRollbar() {
  throw new Error("Hi! I'm testing uncaught errors");
  return <></>;
}

const App = function () {
  const [color, setColor] = useState("");
  let newTheme;

  const [initialScreen, setInitialScreen] = useState<null | Screen>(null);
  const [user, setUser] = useState<null | UserDetails>(null);
  const [urlError, setUrlError] = useState<null | string>(null);
  const [featureFlags, setFeatureFlags] = useState<null | FeatureFlags>(null);
  const [nav, setNav] = useState<NavObject | null>(null);
  const [navigationMenuState, setNavigationMenuState] =
    useState<NavigationMenuState>(NavigationMenuState.INITIAL);

  // navMenu is the left hand nav
  const [navMenuVisible, setNavMenuVisible] = useState(false);
  // navHeader is the hamburger menu items that opens from the header
  const [navHeaderVisible, setNavHeaderVisible] = useState(false);

  const updateNav = async (screen: Screen | null) => {
    // If we managed to pull down the screen with the nav, just set the nav directly from that
    if (screen?.nav) {
      setNav(screen.nav);
    } else {
      if (!navMenuVisible && !navHeaderVisible) return;

      setNavigationMenuState(NavigationMenuState.LOADING);
      try {
        setNav(await getNav());
        setNavigationMenuState(NavigationMenuState.READY);
      } catch (e) {
        setNavigationMenuState(NavigationMenuState.ERROR);
      }
    }
  };

  useEffect(() => {
    // Call updateNav when either visibility state changes
    if (!nav && (navMenuVisible || navHeaderVisible)) {
      updateNav(null);
    }
  }, [navMenuVisible, navHeaderVisible]);

  function identifyUserForLogRocket(userId: string) {
    const pollInterval = 100;
    const maxTime = 10000;
    const maxAttempts = maxTime / pollInterval;
    let attempts = 0;

    const intervalId = setInterval(() => {
      if (window.LogRocket) {
        clearInterval(intervalId);
        window.LogRocket.identify(userId);
      } else if (attempts >= maxAttempts) {
        clearInterval(intervalId);
      }
      attempts++;
    }, pollInterval);
  }

  async function checkUser() {
    const pathname = window.location.pathname;
    if (pathname.includes("/tax-filing")) {
      const result = await getInitialPayloadForDIY();

      if (result.isValid) {
        const data = result.payload as {
          screen: Screen;
          userData: UserDetails;
          featureFlags: FeatureFlags;
        };

        if (data.userData.bankSlug && window.newrelic) {
          window.newrelic.setCustomAttribute(
            "user_host_bank_slug",
            data.userData.bankSlug,
          );
        }

        setColor(data.userData.bankColor || "");

        if (isProduction() || isSandbox() || isHostedInternal()) {
          identifyUserForLogRocket(data.userData.userId);
          configurePerson(data.userData.userId);
        }

        // the calls below depend on params being parsed (via 'getInitialPayloadForDIY' above)
        setInitialScreen(data.screen);
        updateNav(data.screen);
        setFeatureFlags(data.featureFlags);
        setUser(data.userData);
        reportDeviceId();
      } else {
        try {
          rollbar.error("Error with initial screen request URL", {
            location: window.location.href,
            getPayloadErrorMessage: result.errorMessage,
          });
          // eslint-disable-next-line no-empty
        } catch {}
        setUrlError(result.errorMessage);
      }
    }
  }

  const madeInitialRequest = useRef(false);

  useEffect(() => {
    if (!madeInitialRequest.current) {
      madeInitialRequest.current = true;
      checkUser();
    }
  }, []);

  if (color) {
    const darkBrand = tinycolor(color).darken(10).toString();
    const lightBrand = tinycolor(color).setAlpha(0.05).toString();

    newTheme = extendTheme({
      ...theme,
      colors: {
        ...theme.colors,
        brand: {
          light: lightBrand,
          medium: color,
          dark: darkBrand,
        },
      },
    });
  }

  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>
        <Router basename={import.meta.env.BASE_URL || ""}>
          <FontFace />
          <ChakraProvider theme={newTheme || theme}>
            <Routes>
              {/* Routes to verify that we will create Rollbar items on both
              caught and uncaught errors */}
              <Route path="/throw-error" element={<ThrowErrorForRollbar />} />
              <Route
                path="/throw-uncaught-error"
                element={<ThrowUncaughtErrorForRollbar />}
              />

              <Route
                path="/login-via-link"
                element={
                  <Suspense fallback={<div />}>
                    <MagicLinkLogin />
                  </Suspense>
                }
              />

              {/* add stripe checkout */}
              <Route
                path="/checkout"
                element={
                  <Suspense fallback={<div />}>
                    <CheckoutComponent />
                  </Suspense>
                }
              />

              <Route
                path="/admin"
                element={
                  <Suspense fallback={<div />}>
                    <AdminLoginPage
                      computation={isInternalUse()}
                      protectedComponent={() => {
                        if (isInternalUse()) {
                          return <AdminDashboard />;
                        } else {
                          return (
                            <Text m="8">
                              We only allow access to /admin on internal
                              environments
                            </Text>
                          );
                        }
                      }}
                    />
                  </Suspense>
                }
              />

              <Route
                path="/catalog"
                element={
                  <Suspense fallback={<div />}>
                    <AdminLoginPage
                      computation={isInternalUse()}
                      protectedComponent={() => {
                        if (isInternalUse()) {
                          return <CatalogEditorScreen />;
                        } else {
                          return (
                            <Text m="8">
                              We only allow access to /catalog on internal
                              environments
                            </Text>
                          );
                        }
                      }}
                    />
                  </Suspense>
                }
              />

              <Route
                path="/screenbuilder"
                element={
                  <Suspense fallback={<div />}>
                    <AdminLoginPage
                      computation={isInternalUse()}
                      protectedComponent={() => {
                        if (isInternalUse()) {
                          return <ScreenBuilderScreen />;
                        } else {
                          return (
                            <Text m="8">
                              We only allow access to /screenbuilder on internal
                              environments
                            </Text>
                          );
                        }
                      }}
                    />
                  </Suspense>
                }
              />

              <Route
                path="/catalog-start"
                element={
                  <Suspense fallback={<div />}>
                    <AdminLoginPage
                      computation={true}
                      protectedComponent={() => <CatalogStart />}
                    />
                  </Suspense>
                }
              />

              <Route
                path="/catalogue"
                element={
                  <Suspense fallback={<div />}>
                    <Navigate to="/catalog" replace />
                  </Suspense>
                }
              />

              <Route
                path="/dice"
                element={
                  <Suspense fallback={<div />}>
                    <Navigate to="/catalog" replace />
                  </Suspense>
                }
              />

              <Route
                path="/user-review/:userId"
                element={
                  <Suspense fallback={<div />}>
                    <AdminLoginPage
                      protectedComponent={() => <UserReviewPage />}
                    />
                  </Suspense>
                }
              />

              {!isProduction() && (
                <Route
                  path="/training"
                  element={
                    <Suspense fallback={<div />}>
                      <AdminLoginPage
                        protectedComponent={() => <TrainingPage />}
                      />
                    </Suspense>
                  }
                />
              )}

              {isInternalUse() && (
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<div />}>
                      <Navigate to="/admin" replace />
                    </Suspense>
                  }
                />
              )}

              <Route
                path="/submission-dashboard"
                element={
                  <Suspense fallback={<div />}>
                    <SubmissionDashboard />
                  </Suspense>
                }
              />

              <Route
                path="/manual-review-submission-dashboard"
                element={
                  <Suspense fallback={<div />}>
                    <ManualReviewSubmissionDashboard />
                  </Suspense>
                }
              />

              <Route
                path="/review-queue"
                element={
                  <Suspense fallback={<div />}>
                    <ReviewQueue />
                  </Suspense>
                }
              />

              <Route
                path="/submission-rules"
                element={
                  <Suspense fallback={<div />}>
                    <SubmissionRules />
                  </Suspense>
                }
              />

              <Route
                path="/host-bank-dashboard"
                element={
                  <Suspense fallback={<div />}>
                    <HostBankDashboard />
                  </Suspense>
                }
              />

              <Route
                path="/tax-filing"
                element={
                  <Suspense
                    fallback={
                      <Center>
                        <Spinner color="brand.medium" />
                      </Center>
                    }
                  >
                    {(initialScreen || initialScreen === null) && !urlError ? (
                      <FeatureFlagContext.Provider value={featureFlags}>
                        <NavigationMenuContext.Provider
                          value={{
                            nav,
                            navigationMenuState,
                            userDetails: user,
                            navMenuVisible,
                            navHeaderVisible,
                            setNavMenuVisible,
                            setNavHeaderVisible,
                          }}
                        >
                          <TaxFilingSDK
                            initialScreen={initialScreen}
                            userDetails={user}
                            updateNav={updateNav}
                          />
                        </NavigationMenuContext.Provider>
                      </FeatureFlagContext.Provider>
                    ) : isProduction() || isSandbox() ? (
                      <ColumnTaxUnavailableScreen />
                    ) : (
                      <DevErrorScreen error={urlError} />
                    )}
                  </Suspense>
                }
              />

              <Route
                path="/stripe-redirect"
                element={
                  <Suspense
                    fallback={
                      <Center>
                        <Spinner color="brand.medium" />
                      </Center>
                    }
                  >
                    <StripeRedirectPage />
                  </Suspense>
                }
              />

              <Route
                path="/exit"
                element={
                  <Suspense fallback={<div />}>
                    <ExitPage />
                  </Suspense>
                }
              />
            </Routes>
          </ChakraProvider>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
